var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var qV,rV,jV,zV,oV,fla;$CLJS.kV=function(a,b){a=jV(a,b);return $CLJS.m(a)?a:$CLJS.ol};$CLJS.lV=function(a,b){$CLJS.dj.D($CLJS.DH,$CLJS.qG,a,b)};$CLJS.mV=function(a,b,c){a=$CLJS.ry.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.nV=function(a,b){return $CLJS.mV(a,b,null)};qV=function(){$CLJS.jL(oV,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ht],null),$CLJS.Pe($CLJS.Le,$CLJS.r(pV))))};
rV=function(){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ot,new $CLJS.k(null,2,[$CLJS.xk,$CLJS.C,$CLJS.Xu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(pV))};
$CLJS.sV=function(a,b){var c=$CLJS.Oi.h("mbql.clause",$CLJS.ui(a));$CLJS.jL(c,b);$CLJS.Ie($CLJS.r(pV),a)||$CLJS.dj.j(pV,$CLJS.kf,a);return null};$CLJS.tV=function(a,b,c){$CLJS.sV(a,c);$CLJS.AL.o(null,a,function(){return b});return null};$CLJS.wV=function(a,b){$CLJS.R.D(uV,vV,a,b)};$CLJS.yV=function(a,b){$CLJS.R.D(uV,xV,a,b)};
jV=function jV(a,b){return $CLJS.F.h(a,$CLJS.ol)?null:$CLJS.F.h(b,$CLJS.ol)?null:$CLJS.OH(a,b)?b:$CLJS.OH(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:jV.h?jV.h(d,e):jV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};zV=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.AV=new $CLJS.N(null,"short","short",1928760516);oV=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.BV=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);fla=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var pV=$CLJS.cj.g($CLJS.oi);$CLJS.FH(pV,fla,function(){qV();return $CLJS.jL(zV,rV())});qV();$CLJS.jL(zV,rV());$CLJS.jL($CLJS.CL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,oV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,zV],null)],null));
var xV=function xV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
xV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.ev,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ok,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.wL],null)],null)],null)],null),b)],null)};xV.v=1;
xV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var vV=function vV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
vV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.ev,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.wL],null)],null),b)};vV.v=1;vV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var uV=function uV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};uV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.xu)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.tV(b,c,a):$CLJS.sV(b,a)};uV.v=2;
uV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};